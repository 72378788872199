<template>
    <section class="faq-section">
        <h1 class="section-title">FAQs</h1>
        <p class="section-description">
            Find answers to commonly asked questions about 6static, digital art,
            and discord bots.
        </p>
        <AccordionComponent :items="faqItems" />
        <footer class="footer">
            <h2 class="section-title">Still have questions?</h2>
            <p class="">Feel free to reach out to us for further assistance.</p>
            <ButtonComponent :isPrimary="false" :label="'Contact Us'" />
        </footer>
    </section>
</template>
<script>
import AccordionComponent from '@/components/AccordionComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
    components: {
        AccordionComponent,
        ButtonComponent
    },
    data() {
        return {
            faqItems: [
                {
                    question: 'What is 6static?',
                    answer: '6static is a platform for digital art and Discord bot services.'
                },
                {
                    question: 'How can I buy digital art?',
                    answer: `You can purchase digital art on my <a href='https://6static.redbubble.com'>Redbubble Store</a>.`
                },
                {
                    question: 'Can I sell my art on 6static?',
                    answer: 'No.'
                },
                {
                    question: 'What are Discord bots?',
                    answer: 'Discord bots are automated tools that can perform various tasks on your server.'
                },
                {
                    question: 'How can I get a Discord bot?',
                    answer: 'You can get a Discord bot by reaching out to us for custom bot services.'
                }
            ]
        }
    }
}
</script>
<style scoped>
.main-container {
    align-items: center;
    background-color: var(--background-color, #fff);
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    width: 100%;
}

.faq-questions {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    width: 100%;
}

.faq-section {
    margin: 0 auto;
    width: 768px;
    max-width: 100%;
}

.section-title {
    color: var(--primary-color, #000);
    text-align: center;
    font: 700 2rem/120% var(--secondary-font-family, sans-serif);
}

@media (max-width: 991px) {
    .section-title {
        max-width: 100%;
    }
}

.section-description {
    color: var(--primary-color, #000);
    text-align: center;
    margin-top: 2rem;
    font: 400 1.125rem/150% var(--secondary-font-family, sans-serif);
    margin-bottom: 4rem;
}

@media (max-width: 991px) {
    .section-description {
        max-width: 100%;
    }
}

.footer {
    margin-top: 4rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer > * {
    margin: 0.75rem 0;
}
</style>
