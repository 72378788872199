<template>
    <section class="main-container is-column">
        <MediumHeaderComponent
            :title="'Privacy Policy'"
            :description="'This is the privacy policy of our application. We value your privacy and are committed to protecting your personal data.'"
            :showImage="false"
            :showButtons="false"
        />
    </section>
</template>
<script>
import MediumHeaderComponent from '@/components/builder/MediumHeaderComponent.vue'

export default {
    name: 'PrivacyPolicyPage',
    components: {
        MediumHeaderComponent
    }
}
</script>